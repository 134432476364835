/* eslint-disable */
import React, { Component } from 'react';
import cookie from 'react-cookies';
import axios from 'axios';
import { connect } from 'react-redux';
import Slider from "react-slick";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import HomeBanner from "./HomeSubCompo/HomeBanner";

import { appId, apiUrl, apiUrlV2, deliveryId, pickupId, cateringId, reservationId } from "../Helpers/Config";
import { GET_GLOBAL_SETTINGS, GET_STATIC_BLOCK, GET_NORMAL_POPUP } from '../../actions';
import { showLoader, hideLoader, validateEmailFun, showCustomAlert, stripslashes, removeOrderDateTime, removePromoCkValue, addressFormat } from "../Helpers/SettingHelper";

import newletterImg from "../../common/images/newletter-bg.jpg";
import phoneIcon from "../../common/images/float-icon1.png";
import directIcon from "../../common/images/float-icon2.png";
import emailIcon from "../../common/images/float-icon3.png";

var Parser = require('html-react-parser');

class Home extends Component {
	constructor(props) {
		super(props);
		
		var normalpopupFlg = 'initial';
	    if((cookie.load('triggerAvlPop') != '' && cookie.load('triggerAvlPop') != undefined) || (cookie.load('orderPopuptrigger') === 'Yes') || (cookie.load('loginpopupTrigger') === 'Yes') || (cookie.load('promoPopupTrigger') === 'Yes') || (cookie.load('rfcodeAction') === 'Yes')) {
		   normalpopupFlg = 'trigger';
	    }
		
		this.state = { users: [], nextavail: '', cartTriggerFlg: 'No', staticblacks: [], orderTypeBlks: '', doorStepBlck: '', ourStoryBlck: '', viewProductFlg: 'no', viewProductSlug: '',normalpopuplist: [], normalpopupdata: [], normalpopup_status: 'no', normalpopup_flg: normalpopupFlg, normalpopupstatus: 'initial', promo_mail_id: '', isHomePage: 'yes', single_outlet_select:'', single_pickup_outlet:'', single_pickupoutlet_triger:'no'}
		
		this.props.getSettings();
		this.props.getNormalPopup();
		this.props.getStaticBlock();
		
		if(cookie.load('rfcodeAction') !== 'Yes') {
			cookie.remove("referCode");
		    localStorage.removeItem('referCode');
		}
    }
	  
	componentWillReceiveProps(PropsData) {
		if(PropsData.staticblack !== this.state.staticblacks) {
			  var orderTypBlk = '', doorStepBlock='', ourStoryBlock='';
			  if(Object.keys(PropsData.staticblack).length > 0) {
			    PropsData.staticblack.map((data, index) => {
				  if(data.staticblocks_slug === 'order-type-block') {
					  orderTypBlk = data.staticblocks_description;
					  return '';
				  }
				  if(data.staticblocks_slug === 'door-step-block') {
					  doorStepBlock = data.staticblocks_description;
					  return '';
				  }
				  if(data.staticblocks_slug === 'our-story-block') {
					  ourStoryBlock = data.staticblocks_description;
					  return '';
				  }
				});
			  }
			  orderTypBlk = (orderTypBlk !== '' && orderTypBlk !== null) ? Parser(orderTypBlk) : orderTypBlk;
			  doorStepBlock = (doorStepBlock !== '' && doorStepBlock !== null) ? Parser(doorStepBlock) : doorStepBlock;
			  ourStoryBlock = (ourStoryBlock !== '' && ourStoryBlock !== null) ? Parser(ourStoryBlock) : ourStoryBlock;
			  this.setState({staticblacks: PropsData.staticblack, orderTypeBlks: orderTypBlk, doorStepBlck: doorStepBlock, ourStoryBlck: ourStoryBlock});
		  }
		  
		  if(PropsData.normalpopuplist !== this.state.normalpopuplist && this.state.normalpopup_flg === 'initial') {
			var normalpopupMain = PropsData.normalpopuplist, normalpopupdata = [], normalpopupStatus = 'no';
			if(normalpopupMain !== '') {
				if(Object.keys(normalpopupMain).length > 0) {
					var normalpopupIds = cookie.load('normalpopupIds');
					var normalpopupIdArr = (normalpopupIds != '' && normalpopupIds != undefined) ? normalpopupIds.split("~~") : Array();
					if($.inArray(normalpopupMain[0].normalpopup_id, normalpopupIdArr) === -1) {
						normalpopupdata = normalpopupMain[0];
						normalpopupStatus = 'yes';
					}
					this.setState({normalpopuplist: normalpopupMain,normalpopupdata: normalpopupdata,normalpopup_status: normalpopupStatus, normalpopup_flg: 'trigger'});
				}
			}
			
		}
		
	    if(PropsData.normalpopupstatus !== this.state.normalpopupstatus) {
		  this.setState({normalpopupstatus: PropsData.normalpopupstatus});
	    }
		  
	}
	
	chooseAvailability(availability){ 
		var defaultAvilTy = cookie.load('defaultAvilablityId');
		if(defaultAvilTy!==availability){
		  var cartTotalItems = cookie.load('cartTotalItems');
			  cartTotalItems = (cartTotalItems != '' && cartTotalItems != undefined) ? parseInt(cartTotalItems) : 0;
		  if(defaultAvilTy === cateringId && cartTotalItems > 0) {
			  this.setState({nextavail:availability});
			  $.magnificPopup.open({
				items: {
				  src: '#warning-popup'
				},
				type: 'inline'
			  });
			  return false;
		  } else if(cookie.load('orderOutletId') != '' && cookie.load('orderOutletId') != undefined) {
			  this.setState({nextavail:availability});
			  $.magnificPopup.open({
				items: {
				  src: '#warning-popup'
				},
				type: 'inline'
			  });
			  return false;
		  }
		}
		
		var popupIdtxt = '';
		if(availability === deliveryId){
		  console.log('welll', this.state.single_outlet_select);	
		  /*popupIdtxt = '#delevery-popup';*/
		  popupIdtxt = (this.state.single_outlet_select === 'yes') ? '#delevery-postcode-popup' : '#delevery-popup';
		}else if(availability === pickupId){
		  if(this.state.single_pickup_outlet === 'yes') {
			  this.setState({single_pickupoutlet_triger: 'yes'});
			  return false;
		  } else {
			  popupIdtxt = '#takeaway-popup';
		  }			  
		} else if(availability === cateringId) {
			cookie.save("defaultAvilablityId",cateringId);
		  	$.magnificPopup.close();
			this.props.history.push('/catering');
			return false;
		} else if(availability === reservationId) {
			cookie.save("defaultAvilablityId",reservationId);
		  	$.magnificPopup.close();
			this.props.history.push('/reservation');
			return false;
		 /* popupIdtxt = '#comingsoon-popup';*/
		}
		
		if(popupIdtxt !== '') {
			$.magnificPopup.open({
				items: {
				  src: popupIdtxt
				},
				type: 'inline'
			});
		}
	  

	}
	
	componentDidMount() {
		if(cookie.load('triggerAvlPop') != '' && cookie.load('triggerAvlPop') != undefined) {
			var availabilityId = cookie.load('triggerAvlPop');
			cookie.remove('triggerAvlPop');
			var $_this_rec = this;
			setTimeout(function(){
				$_this_rec.chooseAvailability(availabilityId);
			}, 800);
		}
		
		if(cookie.load('orderPopuptrigger') === 'Yes'){
			  cookie.remove('orderPopuptrigger');
			  $.magnificPopup.open({
				items: {
				  src: '#order-popup'
				},
				type: 'inline'
			  });
		}
		
		if(cookie.load('loginpopupTrigger') === 'Yes'){
			  cookie.save("loginpopupTrigger", 'fromcheckout');
			  $.magnificPopup.open({
				items: {
				  src: '#guest-popup'
				},
				type: 'inline'
			  });
			  /*$.magnificPopup.open({
				items: {
				  src: '#login-popup'
				},
				type: 'inline'
			  });*/
		}
		
		if(cookie.load('rfcodeAction') === 'Yes'){
			  cookie.remove('rfcodeAction');
			  localStorage.removeItem('rfcodeAction');
			  $.magnificPopup.open({
				items: {
				  src: '#guest-popup'
				},
				type: 'inline'
			  });
		}
		
		var RctThis = this;
		$('body').off('click', '.act_order_popup').on('click', '.act_order_popup', function(e){
			e.preventDefault();
			
			var odrType = $(this).attr('data-acturl');
			var availabilityId = '';
			if(odrType === 'delivery') {
				availabilityId = deliveryId;
			} else if(odrType === 'pickup') {
				availabilityId = pickupId;
			} else if(odrType === 'catering') {
				availabilityId = cateringId;
			} else if(odrType === 'reservation') {
				availabilityId = reservationId;
			}
			
			if(availabilityId !== '') {
				RctThis.chooseAvailability(availabilityId);
			} else if(availabilityId === '' && odrType === 'ordernow') {
				$.magnificPopup.open({
					items: {
					  src: '#order-popup'
					},
					type: 'inline'
				});
			}
			
		});
	}
	
	sateValChange = (field, value) => {
		if(field === 'cartflg') {
			this.setState({cartTriggerFlg: value});
		}
		if(field === 'view_pro_data' && value !== '') {
			this.setState({viewProductFlg: 'yes',viewProductSlug: value}, function () { this.openProDetailPopup(); }.bind(this));
		}
		if(field === 'view_pro_upate' && value !== '') {
			this.setState({viewProductFlg: value});
		}
		if(field === 'single_outlet_flag' && value !== '') {
			this.setState({'single_outlet_select': value});
		}
		if(field === 'single_pickupoutlet_triger' && value !== '') {
			this.setState({'single_pickupoutlet_triger': value});
		}
		if(field === 'single_pckoutlet_flag' && value !== '') {
			this.setState({'single_pickup_outlet': value});
		}
    }
	
	openProDetailPopup() {
	    showLoader("comboPro-"+this.state.viewProductSlug,'Idtext');
		$("#ProductDetailMdl").modal({backdrop: 'static',keyboard: false});
    }
	 
    triggerNormalPopup(trgType,showNormalPopup,showPromoPopup) {
		
		var otherPageActTrigger = 'no';
		if((cookie.load('triggerAvlPop') != '' && cookie.load('triggerAvlPop') != undefined) || (cookie.load('orderPopuptrigger') === 'Yes') || (cookie.load('loginpopupTrigger') === 'Yes') || (cookie.load('rfcodeAction') === 'Yes')) {
			otherPageActTrigger = 'yes';
		}
		
		if(trgType === 'loading' && showNormalPopup === 'yes' && otherPageActTrigger === 'no' && this.state.normalpopup_status === 'yes' && Object.keys(this.state.normalpopupdata).length > 0 && (cookie.load('promoPopupTrigger') !== 'Yes')) {
				var normalpopupIds = cookie.load('normalpopupIds');
				var normalpopupIdsNew = (normalpopupIds != '' && normalpopupIds != undefined) ? normalpopupIds+"~~"+this.state.normalpopupdata.normalpopup_id : this.state.normalpopupdata.normalpopup_id;
				var normalpopupIdArr = [];
					normalpopupIdArr['normalpopupids'] = normalpopupIdsNew;
				cookie.save('normalpopupIds', normalpopupIdsNew);
				var $_this_rec = this;
				$.magnificPopup.open({
					items: {
						src: '#normal-popup'
					},
					type: 'inline',
					midClick: true,
					closeOnBgClick: false,
					callbacks: {
						close: function() {
						  $_this_rec.normalPopupUpdate();
						}
					}
				});
		}
		
		
		if(((cookie.load('promoPopupTrigger') === 'Yes') || (otherPageActTrigger === 'no' && this.state.normalpopup_status === 'no' && Object.keys(this.state.normalpopupdata).length === 0)) && cookie.load('mailpopopuptrg') !== 'yes' && showPromoPopup === 'yes') { 
			cookie.save('mailpopopuptrg', 'yes');
			cookie.remove("promoPopupTrigger");
			$.magnificPopup.open({
					items: {
						src: '#promo-check-popup'
					},
					type: 'inline',
					midClick: true,
					closeOnBgClick: false
			});
		}
		
	}

    normalPopupUpdate() {
		if(cookie.load('mailpopopuptrg') !== 'yes') {
			this.props.history.push('/refpage/promopopup');
		}
	}

    handleEmailChange(event) 
	{	
		this.setState({promo_mail_id: event.target.value, promomail_error: ''});
	}
	
	sendPromoMailFun() {
		var promoEmail = this.state.promo_mail_id;
		
		var mailErrorTxt = '';
		if(promoEmail === '') {
			mailErrorTxt = 'Email Address is required.';
		} else if(!validateEmailFun(promoEmail)) {
			mailErrorTxt = 'Invalide Email Address';
		}
		
		if(mailErrorTxt !== '') {
			this.setState({promomail_error: mailErrorTxt});
			return false;
		} else {
		
		showLoader('promomailpopup-cls','class');
		
        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "email_address": promoEmail
        };
        
        axios.post(apiUrl + "promotion/user_promotion", qs.stringify(postObject))
            .then(response => {
                hideLoader('promomailpopup-cls','class');
				$.magnificPopup.close();
                if (response.data.status === "ok") {
                    showCustomAlert('success','You are now a Member of Copper Chimney. Please check your email for more information.');
                } else {
					var errMsgtxt = (response.data.message !== '') ? response.data.message : "Sorry! You didn`t have promo code.";
					showCustomAlert('error',errMsgtxt);
                }
                return false;
            });
		}
		
		return false;
	}
	  
	render() {
			  
			    let settingsArr = this.props.globalsettings;
				var showPromoPopup = '', showNormalPopup = '';
				if(Object.keys(settingsArr).length > 0) {
					if(Object.keys(settingsArr[0].result_set).length > 0) {
						if(settingsArr[0].result_set.client_promocode_enable === '1') {
							showNormalPopup = (settingsArr[0].result_set.client_promo_code_normal_popup_enable === '1')?'yes':'';
							showPromoPopup = (settingsArr[0].result_set.client_promocode_options === '1')?'yes':'';
						}
					}
				}
	
		return (<div className="home-main-div">
			
				
				
				{/* Header section */}
				<Header homePageState={this.state} cartTriggerFlg={this.state.cartTriggerFlg} sateValChange={this.sateValChange} />
				
				{/* Home banner section */}
			    <HomeBanner />
			
				{/* Footer section */}
				<Footer />
				
				{/*Normal popup Start*/}
				 <div id="normal-popup" className="white-popup mfp-hide popup_sec normal-popup">
					<div className="normal_popup_alert">
					{(this.state.normalpopup_status === 'yes' && Object.keys(this.state.normalpopupdata).length > 0) && <div className="normal_popup_cont">
						{Parser(this.state.normalpopupdata.normalpopup_description)}
					</div>}
					</div>
				</div>
				{/*Normal popup - End*/}
				
				{/*Promo Check Email popup Start*/}
				 <div id="promo-check-popup" className="white-popup mfp-hide popup_sec promo-check-popup">
					<div className="promopopup-maindiv">
				 
					  <div className="promopopup-lhs">
						 <div className="frm-action-div">
							 <div className="frm-top-title">
								<h3>Join our email list</h3>
								<p>Enter your Email address to receive your promocode</p>
							 </div>
							 
							 <div className="frm-inputbtn-div">
								 <div className="form-group">
									<div className="focus-out">
										<label>Email Address</label>
										<input type="email" className="form-control input-focus" value={this.state.promo_mail_id} onChange={this.handleEmailChange.bind(this)} />
										{(this.state.promomail_error !== '') && <div id='promomail-error'>{this.state.promomail_error}</div>}
									</div>
								</div>
									
								<button type="button" onClick={this.sendPromoMailFun.bind(this)} className="button promomailpopup-cls">Subscribe</button>
								
							</div>
							
							 <div className="frm-bottom-text">
								<p>SIGN UP FOR EXCLUSIVE UPDATES, NEW PRODUCTS, 
AND INSIDER-ONLY DISCOUNTS</p>
							 </div>
							
						 </div>
					  </div>
					  
					  <div className="promopopup-rhs">
							 <img src={newletterImg} />
					  </div>
					  
					 </div>
				</div>
				{/*Promo Check Email popup - End*/}
				
				{(this.state.normalpopupstatus !== 'initial' && (showNormalPopup === 'yes' || showPromoPopup === 'yes')) && this.triggerNormalPopup('loading',showNormalPopup,showPromoPopup)}
			
			   </div>)
	}	
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if(Object.keys(state.staticblack).length > 0) {
		 if(state.staticblack[0].status === 'ok') {
			 blacksArr = state.staticblack[0].result_set;
		 }
  }
  
  var normalPopupStatus = 'initial';
  var normalPopupArr = Array();
  if(Object.keys(state.normalpopuplist).length > 0) {
		 if(state.normalpopuplist[0].status === 'ok') {
			 normalPopupArr = state.normalpopuplist[0].result_set;
			 normalPopupStatus = 'ok';
		 } else {
			 normalPopupStatus = 'error';
		 }
  }	
  
  return {
	globalsettings: state.settings,  
    staticblack: blacksArr,
    normalpopuplist: normalPopupArr,
    normalpopupstatus: normalPopupStatus
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },   
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
	getNormalPopup: () => {
      dispatch({ type: GET_NORMAL_POPUP });
    },
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
