import { SET_FAVOURITEPRODUCT } from '../actions';

const favouriteproduct = (state = [], action) => {
  switch (action.type) {
	case SET_FAVOURITEPRODUCT:
      return [...action.value];  
    default: return state;
  }
}

export default favouriteproduct;