/* eslint-disable */
import React, { Component } from 'react';

import { Link} from 'react-router-dom';

import Slider from "react-slick";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { stripslashes, hideLoader, showAlert} from "../Helpers/SettingHelper";

import { appId, apiUrl, apiUrlV2, timThumpUrl, reservationId } from "../Helpers/Config";


var Parser = require('html-react-parser');

import update from 'immutability-helper'

import { validated } from 'react-custom-validation'
import validator from 'validator'

import axios from 'axios';
import cookie from 'react-cookies';

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import noimage from "../../common/images/noimg-470x240.jpg";
import innerBanner from "../../common/images/inner-banner.jpg";
import phoneIcon from "../../common/images/float-icon1.png";
import directIcon from "../../common/images/float-icon2.png";
import emailIcon from "../../common/images/float-icon3.png";

import { GET_PICKUP_OUTLETS, GET_GLOBAL_SETTINGS} from '../../actions';




class Pages extends Component {

    constructor(props) {
        super(props);
		this.state = {outlets:[], globalsettings: [], starttime:'', endtime:'', outletsimagesource:''};
    }

    componentDidMount() {
            this.props.getPickupOutlets();      
			this.props.getGlobalSettings();
            $('html, body').animate({ scrollTop: 0 }, 800);
    }
    
    componentWillReceiveProps(nextProps) {


        if(nextProps.outlets !==this.props.outlets){
		   if(nextProps.outlets && nextProps.outlets[0].status == 'ok'){
				 $('#dvLoading').fadeOut(2000);
				 var outletsimagesource = nextProps.outlets[0].common.image_source;
                 this.setState({outlets: nextProps.outlets[0].result_set, outletsimagesource: outletsimagesource});
              }
          }

	 if(nextProps.globalsettings !==this.props.globalsettings){
		   if(nextProps.globalsettings && nextProps.globalsettings[0].status == 'ok'){

                 this.setState({starttime: nextProps.globalsettings[0].result_set.client_start_time,endtime: nextProps.globalsettings[0].result_set.client_end_time});

              }
          }

        
	}
	
 tConvert (time) {

  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { 
    time = time.slice (1);  
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; 
    time[0] = +time[0] % 12 || 12; 
  }
  return time.join (''); 
}

allInOnepopup() {
	/*$.magnificPopup.open({
	  items: {
		src: '#order-popup'
	  },
	  type: 'inline'
	});*/
	
	cookie.save("defaultAvilablityId",reservationId);
	this.props.history.push('/reservation');
}

gotoContacus(outletId) {
	cookie.save("contusOutletId",outletId);
	this.props.history.push('/contact-us');
}

callPhoneOptn(phoneTxt) {
	var resultTxt = '';
	if(phoneTxt !== '') {
		if(phoneTxt.indexOf("+65") !== -1) {
			resultTxt = "tel:"+phoneTxt;
		} else if((phoneTxt.indexOf("65") !== -1) && (phoneTxt.length >= 10)) {
			resultTxt = "tel:+"+phoneTxt;
		} else {
			resultTxt = "tel:+65"+phoneTxt;
		}
	} else {
		resultTxt = 'javascript:void(0);';
	}
	
	return resultTxt;
}

timingCnt(outletId) {
	/* jankosoft 'Dempsey' => 190 'Suntec' => 191 */
	if(outletId === '218' || outletId === '217') {
		return (<span>Lunch: 11.30AM to 2.30PM <br></br>  Dinner: 6.00PM to 09.30PM</span>);
		
	/* jankosoft 'Race Course' => 192 */	
	} else if(outletId === '216') {
		return (<span>Timing: 10.30 AM to 10.30 PM</span>);	
	} else {
		return (<span>Timing: {this.tConvert(this.state.starttime)} to {this.tConvert(this.state.endtime)}</span>);
	}
}

getOutletData = (dataProp) =>{
  if (dataProp) { 
       var outletsimagesource = this.state.outletsimagesource;
	   return dataProp.map((item, index) =>{
			   return (<li key={index} >
					   <div className="ourrest_row">
							   <div className="ourrest_img">
							   {(item.outlet_image !== '') ?  <img src={outletsimagesource+item.outlet_image} alt="Outlet Img" /> : <img className="media-object" src={noimage} /> }
								   
							   </div>
							   <div className="ourrest_info">
									   <h4>{stripslashes(item.outlet_name)}</h4>
									   <p>{item.outlet_address_line1}</p>
									   <p>{(item.outlet_unit_number2 !== '')?'#'+item.outlet_unit_number1+'-'+item.outlet_unit_number2:item.outlet_unit_number1} S ( {item.outlet_postal_code} )</p>
									   <p>
									   {(item.outlet_email !== '') && <span>Email: {item.outlet_email} <br></br></span>}
									   Phone: <span className="phone-show-dskp">{item.outlet_phone}</span><span className="phone-show-mbl"> <a href={this.callPhoneOptn(item.outlet_phone)}>{item.outlet_phone}</a></span> <br></br> 
									   
									   {this.timingCnt(item.outlet_id)}
									   
									   </p>
									<ul className="ourrest_infolinks">
										<li className="media-links-b li-full-width">
											{/*<a href="javascript:void(0);" onClick={this.allInOnepopup.bind(this)} rel="nofollow" className="readmore font-headings"><i className="fa fa-cutlery"></i>Make A Order Now </a>*/}
											<a href="/products" rel="nofollow" className="readmore font-headings"><i className="fa fa-cutlery"></i>Make A Order Now </a>
										</li>
									</ul>
							   </div>
					   </div>
			   </li>);
		   })
  }        
}
	

	
  callPhoneOptn(phoneTxt) {
		var resultTxt = '';
		if(phoneTxt !== '') {
			if(phoneTxt.indexOf("+65") !== -1) {
				resultTxt = "tel:"+phoneTxt;
			} else if((phoneTxt.indexOf("65") !== -1) && (phoneTxt.length >= 10)) {
				resultTxt = "tel:+"+phoneTxt;
			} else {
				resultTxt = "tel:+65"+phoneTxt;
			}
		} else {
			resultTxt = 'javascript:void(0);';
		}
		
		return resultTxt;
  }
  
  clientInfoTech() {
		let settingsArr = this.props.globalsettings;
		var clientPhone = '', clientEmail = '', clientDirection = '';
		if(Object.keys(settingsArr).length > 0) {
			if(Object.keys(settingsArr[0].result_set).length > 0) {
				clientPhone = settingsArr[0].result_set.client_company_phone;
				clientEmail = settingsArr[0].result_set.client_email_address;
				clientDirection = settingsArr[0].result_set.client_company_direction_url;
			}
		}
		
		if(clientPhone !== '' || clientEmail !== '' || clientDirection !== '') {
			return(<div className="client-info-tech">
					<ul>
					{(clientPhone !== '') && <li className="client-phone-act">
					<span className="phone-show-dskp"><img src={phoneIcon} /></span><span className="phone-show-mbl"> <a href={this.callPhoneOptn(clientPhone)}><img src={phoneIcon} /></a></span>
					</li>}
					{(clientEmail !== '') && <li className="client-mail-act">
					<span className="phone-show-dskp"><img src={emailIcon} /></span><span className="phone-show-mbl"> <a href={'mailto:'+clientEmail}><img src={emailIcon} /></a></span>
					</li>}
					{(clientDirection !== '') && <li className="client-direction-act">
					<a href={clientDirection} target="_blank"><img src={directIcon} /></a>
					</li>}
					</ul>
				   </div>);
		} else {
			return '';
		}
		
  }	

 render() {
	   return (<div className="outletList-main-div">
		
				 
				 {/* Header start */}
				 <Header />
				 {/* Header End */}
				
				<div className="common-inner-blckdiv">
					<div className="common-inner-banner">
						<img src={innerBanner} />
						<p>Our Outlets</p>
					</div>
					{this.clientInfoTech()}
				 </div>
				
				 <div className="outlet-lst-page">
					<div className="container-one cms-content">
					  <div className="container">
					    <h3 className="rest-list-cls">Our Restaurants</h3>
						<ul className="outletul">
							{this.getOutletData(this.state.outlets)}
						</ul>
					  </div>
					</div>
				  </div>
			   
			   <Footer />
			   <div id="dvLoading1234"></div>
	   </div>);
 }
 
}



const mapStateToProps = (state) => {
return {
outlets: state.outlets,
globalsettings: state.settings,
}
}
const mapDispatchToProps = (dispatch) => {
return {
getPickupOutlets: () => {
dispatch({ type: GET_PICKUP_OUTLETS});
},
getGlobalSettings: () => {
dispatch({ type: GET_GLOBAL_SETTINGS });
},
}
}
Pages.propTypes = {
history: PropTypes.shape({
push: PropTypes.func.isRequired
}) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));

 
