/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_FAVOURITEPRODUCT, SET_FAVOURITEPRODUCT } from '../actions';
import { appId, apiUrl, apiUrlV2, deliveryId } from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from 'react-cookies';

export const watchGetFavouritePro = function* () {
  yield takeEvery(GET_FAVOURITEPRODUCT, workerGetFavouritePro);
}

function* workerGetFavouritePro({ outletId }) {
  try {
	var availabilityId = (cookie.load("defaultAvilablityId") === undefined || cookie.load("defaultAvilablityId") == '' )?deliveryId:cookie.load("defaultAvilablityId");
    const uri = apiUrl+'products/favourite_products_list?app_id='+appId+"&customer_id="+cookie.load("UserId")+"&availability_id="+availabilityId+"&outlet_id="+outletId;
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_FAVOURITEPRODUCT, value: resultArr });
  } 
  catch {
    console.log('Get Favourite Product Failed');
  }
} 
