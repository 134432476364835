/* eslint-disable */
import React, { Component } from 'react';

import { Link} from 'react-router-dom';

import Slider from "react-slick";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { stripslashes, hideLoader, showAlert} from "../Helpers/SettingHelper";

import { appId, apiUrl, apiUrlV2, timThumpUrl, reservationId } from "../Helpers/Config";


var Parser = require('html-react-parser');

import update from 'immutability-helper'

import { validated } from 'react-custom-validation'
import validator from 'validator'

import axios from 'axios';
import cookie from 'react-cookies';

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import noimage from "../../common/images/noimg-470x240.jpg";
import innerBanner from "../../common/images/inner-banner.jpg";
import phoneIcon from "../../common/images/float-icon1.png";
import directIcon from "../../common/images/float-icon2.png";
import emailIcon from "../../common/images/float-icon3.png";

import { GET_FAQ_LIST, GET_GLOBAL_SETTINGS} from '../../actions';




class Faq extends Component {

    constructor(props) {
        super(props);
		this.state = {faqlist:[], globalsettings: [], starttime:'', endtime:'', outletsimagesource:''};
    }

    componentDidMount() {
            this.props.getFaqList();      
			this.props.getGlobalSettings();
            $('html, body').animate({ scrollTop: 0 }, 800);
    }
    
    componentWillReceiveProps(nextProps) {

        if(nextProps.faqlist !==this.props.faqlist){
		   if(nextProps.faqlist && nextProps.faqlist[0].status == 'ok'){
				 $('#dvLoading').fadeOut(2000);
                 this.setState({faqlist: nextProps.faqlist[0].result_set});
              }
          }

		if(nextProps.globalsettings !==this.props.globalsettings){
		   if(nextProps.globalsettings && nextProps.globalsettings[0].status == 'ok'){

                 this.setState({starttime: nextProps.globalsettings[0].result_set.client_start_time,endtime: nextProps.globalsettings[0].result_set.client_end_time});

              }
          }

        
	}
	
	handlerActTab(indx,indx1) {
		var inxtxt = indx+indx1;
		var ariaAxp = $("#headingTb"+inxtxt).find('a').attr('aria-expanded');
		if(ariaAxp === 'true') {
			$("#headingTb"+inxtxt).addClass("act");
		} else {
			$("#headingTb"+inxtxt).removeClass("act");
		}
		$('.panel-heading').not($("#headingTb"+inxtxt)).find('a').attr("aria-expanded","false");
		$('.panel-heading').not($("#headingTb"+inxtxt)).removeClass("act");
		$('.panel-collapse').not($("#collapse"+inxtxt)).removeClass("in");
		/*$(".main-faq-lst").each(function( index ) {
		  this.
		});*/
	}
	
   getFaqDataList() {
	   
		var faqlistArr = this.state.faqlist;
		var faqlistHtml = faqlistArr.map((fqLst, indx) =>
								  <div className="main-faq-lst" key={indx}>
								    <h3 className="rest-list-cls">{fqLst.faqcategory_title}</h3>
									{(Object.keys(fqLst.faqs).length > 0)?this.faqDataView(indx,fqLst.faqs):''}
								  </div>); 
		return faqlistHtml;

   }

   faqDataView(indx,faqDtLst) {
	    var indxNew = indx+'-';
	    var faqDtHtml = Object.keys(faqDtLst).map((fqDtkey, indx1) =>
								  <div className="panel panel-default" key={indxNew+indx1}>
										<div className="panel-heading" role="tab" id={"headingTb"+indxNew+indx1} onClick={this.handlerActTab.bind(this,indxNew,indx1)}>
											<h4 className="panel-title">
												<a role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" href={"#collapse"+indxNew+indx1} className="collapsed">{faqDtLst[fqDtkey].faq_title}</a>
											</h4>
										</div>
										<div id={"collapse"+indxNew+indx1} className="panel-collapse collapse" aria-expanded="false" href={"#collapse"+indxNew+indx1}>
											<div className="panel-body">
											 
											 {Parser(faqDtLst[fqDtkey].faq_description)}
											 
											</div>
										</div>	
									</div>); 
		return faqDtHtml;
   }
	
  callPhoneOptn(phoneTxt) {
		var resultTxt = '';
		if(phoneTxt !== '') {
			if(phoneTxt.indexOf("+65") !== -1) {
				resultTxt = "tel:"+phoneTxt;
			} else if((phoneTxt.indexOf("65") !== -1) && (phoneTxt.length >= 10)) {
				resultTxt = "tel:+"+phoneTxt;
			} else {
				resultTxt = "tel:+65"+phoneTxt;
			}
		} else {
			resultTxt = 'javascript:void(0);';
		}
		
		return resultTxt;
  }
  
  clientInfoTech() {
		let settingsArr = this.props.globalsettings;
		var clientPhone = '', clientEmail = '', clientDirection = '';
		if(Object.keys(settingsArr).length > 0) {
			if(Object.keys(settingsArr[0].result_set).length > 0) {
				clientPhone = settingsArr[0].result_set.client_company_phone;
				clientEmail = settingsArr[0].result_set.client_email_address;
				clientDirection = settingsArr[0].result_set.client_company_direction_url;
			}
		}
		
		if(clientPhone !== '' || clientEmail !== '' || clientDirection !== '') {
			return(<div className="client-info-tech">
					<ul>
					{(clientPhone !== '') && <li className="client-phone-act">
					<span className="phone-show-dskp"><img src={phoneIcon} /></span><span className="phone-show-mbl"> <a href={this.callPhoneOptn(clientPhone)}><img src={phoneIcon} /></a></span>
					</li>}
					{(clientEmail !== '') && <li className="client-mail-act">
					<span className="phone-show-dskp"><img src={emailIcon} /></span><span className="phone-show-mbl"> <a href={'mailto:'+clientEmail}><img src={emailIcon} /></a></span>
					</li>}
					{(clientDirection !== '') && <li className="client-direction-act">
					<a href={clientDirection} target="_blank"><img src={directIcon} /></a>
					</li>}
					</ul>
				   </div>);
		} else {
			return '';
		}
		
  }	

 render() {
	   return (<div className="outletList-main-div">
		
				 
				 {/* Header start */}
				 <Header />
				 {/* Header End */}
				
				<div className="common-inner-blckdiv">
					<div className="common-inner-banner">
						<img src={innerBanner} />
						<p>Faq</p>
					</div>
					{this.clientInfoTech()}
				 </div>
				
				 <div className="faq-lst-page">
					<div className="container-one cms-content">
					  <div className="container">
					   
						    <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
								{this.getFaqDataList()}
						    </div>
						
						
					  </div>
					</div>
				  </div>
			   
			   <Footer />
			   <div id="dvLoading1234"></div>
	   </div>);
 }
 
}



const mapStateToProps = (state) => {
return {
faqlist: state.faqlist,
globalsettings: state.settings,
}
}
const mapDispatchToProps = (dispatch) => {
return {
getFaqList: () => {
dispatch({ type: GET_FAQ_LIST});
},
getGlobalSettings: () => {
dispatch({ type: GET_GLOBAL_SETTINGS });
},
}
}
Faq.propTypes = {
history: PropTypes.shape({
push: PropTypes.func.isRequired
}) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Faq));

 
