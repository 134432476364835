import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_FAQ_LIST, SET_FAQ_LIST } from '../actions';
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from 'axios';

export const watchGetFaq = function* () {
  yield takeEvery(GET_FAQ_LIST, workerGetFaq);
}

function* workerGetFaq() {
  try {
    const uri = apiUrl+'cms/faq?app_id='+appId;
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_FAQ_LIST, value: resultArr });
  } 
  catch {
    console.log('Get faq Failed');
  }
} 
