import { all } from 'redux-saga/effects'
import { watchGetSettings } from './settings';
import { watchGetStaticBlack } from './staticblack';
import { watchGetCartDetail, watchUpdateCartDetail, watchDeleteCartDetail, watchDestroyCartDetail } from './cartlist';
import { watchGetPickupOutlets} from './outlets';
import { watchGetAllOutlets } from './alloutlets';
import { watchGetZonedetail } from './zonedetail';
import { watchGetBanner } from './banner';
import { watchGetFaq } from './faq';
import { watchGetFeaturePro } from './featureproduct';
import { watchGetMenuNavigation, watchGetProducts } from './product';
import { watchGetProductDetail } from './productdetail';
import { watchGetOrderDetail } from './orderdetail';
import { watchGetAddonPro } from './addonproduct';
import { watchGetFavouritePro } from './favouriteproduct';
import { watchGetLoginData } from './login';
import { watchGetFbLoginData } from './fblogin';
import { watchGetCustomerDetail } from './customerdetail';
import { watchGetForgetPassword } from './forgetpassword';
import { watchGetRegistration } from './registration';
import { watchGetChangePassword } from './changepassword';
import { watchGetUpdateCustomerProfile } from './updatecustomerprofile';
import { watchGetCorderDetail } from './corderdetail';
import { watchGetPorderDetail } from './porderdetail';
import { watchGetPrintOrder } from './printorder';
import { watchGetOrderHistory } from './orderhistory';
import { watchGetPromotionList } from './promotionlist';
import { watchGetPromotionReceipt } from './promotionreceipt';
import { watchGetApplyPromotion } from './applypromotion';
import { watchGetActivityCount } from './activitycount';
import { watchGetRewardEarned } from './rewardearned';
import { watchGetRewardRedeem } from './rewardredeem';
import { watchGetRequestpage } from './pages';
import { watchGetCateringOrder } from './cateringorder';
import { watchGetCateringPast } from './cateringpast';
import { watchGetCateringHistory } from './cateringhistory';
import { watchGetMenuData } from './menudata';
import { watchGetContactData } from './contactdata';
import { watchGetNewsData } from './news';
import { watchGetCateringPrint } from './cateringprint';
import { watchGetHolidays } from './holidays';
import { watchGetCategory } from './cateringcategory';
import { watchGetOwnvenueCategory } from './cateringownvenuecategory';
import { watchGetHallCategory } from './cateringhallcategory';
import { watchGetHall } from './cateringhall';
import { watchGetAvailabileDateList, watchGetDefaultAvlDateList } from './availabiledate';
import { watchGetAvailabileTimeList } from './availabiletime';
import { watchGetCateringProduct } from './cateringprodcutlist';
import { watchGetCateringProductDetail } from './cateringproductdetail';
import { watchGetCateringCartDetail, watchUpdateCateringCartDetail, watchDeleteCateringCartDetail, watchDeleteInvCateringCartDetail, watchDestroyCateringCartDetail } from './cateringcartlist';
import { watchGetOrderRequest } from './orderrequestlist';
import { watchGetPaymentdata } from './cateringpaymentdata';
import { watchGetResAvailabileDateList } from './reservationdate';
import { watchGetResAvailabileTimeList } from './reservationtime';
import { watchGetgetResOrdersubmit } from './reservationordersubmit';
import { watchGetSecAddress, watchAddSecAddress } from './secondaryaddress';
import { watchGetReservationList } from './reservationlist';
import { watchGetNormalpopup } from './normalpopup';

export default function* () {
  yield all([
    watchGetSettings(),
    watchGetStaticBlack(),
    watchGetCartDetail(),
    watchUpdateCartDetail(),
    watchDeleteCartDetail(),
    watchDestroyCartDetail(),
    watchGetPickupOutlets(),
    watchGetAllOutlets(),
    watchGetZonedetail(),
    watchGetBanner(),
    watchGetFaq(),
    watchGetFeaturePro(),
    watchGetMenuNavigation(),
    watchGetProducts(),
    watchGetProductDetail(),
    watchGetAddonPro(),
    watchGetFavouritePro(),
    watchGetOrderDetail(),
	watchGetLoginData(),
    watchGetFbLoginData(),
    watchGetCustomerDetail(),
    watchGetForgetPassword(),
    watchGetRegistration(),
    watchGetChangePassword(),
    watchGetUpdateCustomerProfile(),
    watchGetCorderDetail(),
    watchGetPorderDetail(),
    watchGetPrintOrder(),
    watchGetOrderHistory(),
    watchGetPromotionList(),
    watchGetPromotionReceipt(),
    watchGetApplyPromotion(),
	watchGetActivityCount(),
    watchGetRewardEarned(),
    watchGetRewardRedeem(),
	watchGetRequestpage(),
    watchGetCateringOrder(),
    watchGetCateringPast(),
    watchGetCateringHistory(),
    watchGetMenuData(),
    watchGetContactData(),
    watchGetNewsData(),
	watchGetCateringPrint(),
	watchGetHolidays(),
	watchGetCategory(),
	watchGetOwnvenueCategory(),
	watchGetHallCategory(),
	watchGetHall(),
	watchGetAvailabileDateList(),
	watchGetDefaultAvlDateList(),
	watchGetAvailabileTimeList(),
	watchGetCateringProduct(),
	watchGetCateringProductDetail(),
	watchGetCateringCartDetail(),
	watchUpdateCateringCartDetail(),
	watchDeleteCateringCartDetail(),
	watchDeleteInvCateringCartDetail(),
	watchDestroyCateringCartDetail(),
	watchGetOrderRequest(),
	watchGetPaymentdata(),
	watchGetResAvailabileDateList(),
	watchGetResAvailabileTimeList(),
	watchGetgetResOrdersubmit(),
	watchGetSecAddress(),
	watchAddSecAddress(),
	watchGetReservationList(),
	watchGetNormalpopup()
  ])
}
