/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Slider from "react-slick";
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { appId, apiUrl, apiUrlV2, deliveryId, timThumpUrl } from "../Helpers/Config";
import { getReferenceID, stripslashes, showPriceValue, callImage, showLoader, hideLoader, showCustomAlert, showCartLst, showAlert, removePromoCkValue,capitalize } from "../Helpers/SettingHelper";
import noimage from "../../common/images/no-img-product.png";
import heartWhite from "../../common/images/heart-white.png";
import heartYellow from "../../common/images/heart-yellow.png";

import { GET_PRODUCT_LIST, GET_FAVOURITEPRODUCT } from '../../actions';
var Parser = require('html-react-parser');
var qs = require('qs');

class ProductList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedNavigation: "",
      selectedslugType: "",
      productSubcatlist: [],
      productCommon: [],
      productDetails: [],
      product_List: [],
      filter_Tag: [],
      imagesource: "",
      viewProductSlug: "",
      OldupdateproductList: [],
      oldnavigateMenu: [],
      selProductEndSection: [],
      FinalDetailsProduct: [],
    }
	
	var orderOutletId = (cookie.load('orderOutletId') != '' && cookie.load('orderOutletId') != undefined) ? cookie.load('orderOutletId') : '';
	var UserId = (cookie.load('UserId') != '' && cookie.load('UserId') != undefined) ? cookie.load('UserId') : '';
	if(UserId !== '') {
		this.props.getFavouriteProList(orderOutletId);
	}
  }

  componentWillReceiveProps(nextProps) {
    let selectedNavigation = nextProps.productState.selectedNavigation;
    let slugType = nextProps.productState.catslugType;
    if (selectedNavigation !== this.state.selectedNavigation) {
      if (selectedNavigation !== "") {
        var catSlug = selectedNavigation;
        var subcatSlug = "";
        if (slugType === "subcategory") {
          catSlug = "";
          subcatSlug = selectedNavigation;
        }
        this.getCateProductList(nextProps.productState.navigateMenu);
        // this.props.getProductList(catSlug, subcatSlug, "");
      }
      this.setState({
        selectedNavigation: selectedNavigation,
        selectedslugType: slugType,
      });
    }
    if (nextProps.productState.setFiltetTag === "Yes") {
      this.setState(
        { filter_Tag: nextProps.productState.filterTag },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("setFiltetTag", "No");
        }
      );
    }

    if (this.state.productSubcatlist !== nextProps.productSubcatlist) {
      this.setState(
        { productSubcatlist: nextProps.productSubcatlist },
        function () {
          this.productsubcatlist();
        }
      );
    }
  }
  productsubcatlist(updateproductList, navigateMenu) {
    let current = this;

    if (navigateMenu !== "" && navigateMenu !== undefined) {
      this.setState({
        OldupdateproductList: updateproductList,
        oldnavigateMenu: navigateMenu,
      });
		  hideLoader('productlist-main-div','class');
      if (navigateMenu !== "" && navigateMenu.length > 0) {
        var product_List = navigateMenu.map(function (item, index) {
          return (
            <div id={item.pro_cate_slug} key={index}>
              <div
                className="product-category-list"
                key={index}
                id={"pro_cat_" + item.pro_cate_slug}
              >
                <h2>{capitalize(stripslashes(item.pro_cate_name))}</h2>
                {item.pro_cate_short_description !== "" && (
                  <p className="short_desc">{stripslashes(item.pro_cate_short_description)}</p>
                )}
                {updateproductList[item.pro_cate_slug] !== "" &&
                updateproductList[item.pro_cate_slug] !== undefined
                  ? updateproductList[item.pro_cate_slug][0].subcategorie.map(
                      function (categories, sindex) {
                        return (
                          <div className="innerproduct" id={"pro_cat_" + categories.pro_subcate_slug} key={sindex}>
                            <h3>{stripslashes(capitalize(categories.pro_subcate_name))}</h3>
                            <ul className="products-list-ulmain">
                              {current.productList(
                                categories.products,
                                categories.products.length
                              )}
                            </ul>
                          </div>
                        );
                      }
                    )
                  : ""}
              </div>
            </div>
          );
        });
        this.setState({ product_List: product_List }, function () {
          hideLoader("productlist-main-div", "class");
        });
      }
    }
  }

  
  chkProStockCls(proSlug,Stock) {
	  
	  var searchProVal = this.props.productState.searchProVal;
	  var actClstxt = (searchProVal === proSlug) ? ' active' : '';
	  
	  var returnText = "products-single-li no-stock-product " + proSlug + actClstxt;
	  if(Stock > 0) {
		  returnText = "products-single-li " + proSlug + actClstxt;
	  }
	  
	  return returnText;
  }
  
  /* Products List */
  productList(list, productcount, catsubcatArr) {
    var imageSource = this.state.imagesource.product_image_source;
    var tagImageSource = this.state.imagesource.tag_image_source;
	  var slugType = this.state.selectedslugType;
	  var naviSlug = this.state.selectedNavigation;
	  var clientProductStock = '';
	  if(Object.keys(this.props.globalsettings).length > 0) {
		if(this.props.globalsettings[0].status === 'ok') {
		  var globalSettingsArr = this.props.globalsettings[0].result_set;
		  if(Object.keys(globalSettingsArr).length > 0) {
			  clientProductStock = globalSettingsArr.client_product_stock;
		  }			  
		}
	  }
	  
	  
	  const listProduct = list.map((productDetail, index) => {
			
			var description = (productDetail.product_short_description !== null && productDetail.product_short_description !== '') ? Parser(stripslashes(productDetail.product_short_description)) : '';

			var prodivId = "proIndex-"+productDetail.product_primary_id;
			var comboProId = "comboPro-"+productDetail.product_slug;

			return (<li className={this.chkProStockCls(productDetail.product_slug,productDetail.product_stock)} id={prodivId} key={index} >
					<div className="products-image-div">
					    {cookie.load("UserId") &&  <a className={this.getFavActivCls(productDetail)} href="/" onClick={this.favourActFun.bind(this,productDetail)}>
							<img className="disbl_href_action favour-heartwhite-icon" src={heartWhite} />
							<img className="disbl_href_action favour-heartyellow-icon" src={heartYellow} />
						</a>}
						{(productDetail.product_thumbnail !== '')?<img src={imageSource+'/'+productDetail.product_thumbnail} />:<img src={noimage} />}
					</div>

					<div className="product-info-div">
					  <div className="product-title-maindiv">
						   <div className="product-title">
							 <h3>{productDetail.product_alias !== '' ? stripslashes(productDetail.product_alias) : stripslashes(productDetail.product_name)}</h3>
						   </div>
						   {(Object.keys(productDetail.product_tag).length > 0)?<div className="product-tag-list">
							 <ul>
							  { 
								(productDetail.product_tag).map(
								  (producttag,index1) => {
								  return (<li key={index1}>{(producttag.pro_tag_image !== '')?<img src={tagImageSource+producttag.pro_tag_image} />:''}</li>) 
								 })
							  }
							 </ul>
						   </div>:''}
					   </div>

					   <div className="product-short-description"><p>{(description!=='')?description:Parser('&nbsp;')}
					   </p></div>
					   
					   <div className="product-price">
							<h3>{showPriceValue(productDetail.product_price)}</h3>
					   </div>
					   
					   <div className="products-ordernow-action">
					   {(productDetail.product_stock > 0 || productDetail.product_stock === null || clientProductStock !== '1')?((productDetail.product_type === '1')?<a className="button order_nowdiv smiple_product_lk disbl_href_action" href="/" onClick={this.viewProDetail.bind(this,productDetail)}>Add to Cart</a>:<a href="/" onClick={this.viewProDetail.bind(this,productDetail)} title="Product Details" id={comboProId} className="button order_nowdiv compo_product_lk">Order Now</a>):<a className="button order_nowdiv disabled disbl_href_action" href='/'>Sold Out</a>}
						
						<div className="addcart_row addcart_done_maindiv">
							<div className="qty_bx">
								<span className="qty_minus" onClick={this.proQtyAction.bind(this,productDetail.product_primary_id, 'decr')}>-</span>
								<input type="text" className="proqty_input" readOnly value="1" />
								<span className="qty_plus" onClick={this.proQtyAction.bind(this,productDetail.product_primary_id, 'incr')}>+</span>
							</div>
							<button className="btn btn_black" onClick={this.addToCartSimple.bind(this,productDetail,'done',catsubcatArr)}>Done</button>
						</div>
					   </div>
					   
					</div>
				</li>);
			});
		
		return listProduct;
  }
  
  proQtyAction(indxFlg, actionFlg) {
	  var proqtyInput = $("#proIndex-"+indxFlg).find('.proqty_input').val();
		  proqtyInput = parseInt(proqtyInput);
	  if (actionFlg === 'decr') {
		  proqtyInput = (proqtyInput > 1) ? proqtyInput - 1 : proqtyInput;
	  } else {
		  proqtyInput = proqtyInput + 1;
	  }
	  $("#proIndex-"+indxFlg).find('.proqty_input').val(proqtyInput);
  }
  
  /* add to cart */
  addToCartSimple(productDetail, actionFlg, catsubcatArr=Array(), event) {
	
	event.preventDefault();
	
	var orderOutletId = cookie.load('orderOutletId');
	if(orderOutletId === '' || orderOutletId === undefined) {
		var shareProUrl = (Object.keys(catsubcatArr).length > 0) ? catsubcatArr['pro_cate_slug']+"/"+catsubcatArr['pro_subcate_slug']+"/"+productDetail.product_slug : '';
		cookie.save("orderPopuptrigger",'Yes');
		cookie.save("shareProUrl",shareProUrl);
		this.props.history.push('/');
		return false;
	}
	
	var IndexFlg = productDetail.product_primary_id;
	
	if (actionFlg === 'initial') {
		$("#proIndex-"+IndexFlg).addClass('active');
		$("#proIndex-"+IndexFlg).find('.smiple_product_lk').hide();
		$("#proIndex-"+IndexFlg).find('.addcart_done_maindiv').show();
		return false;
	} else {
		showLoader('proIndex-'+IndexFlg,'Idtext');
		var availabilityId = cookie.load("defaultAvilablityId");
		/*var availabilityId = deliveryId;*/
		var availabilityName = (availabilityId === deliveryId) ? "Delivery" : "Pickup";
		var isAddonProduct = 'No';
		var productId = productDetail.product_id
		var customerId = (typeof cookie.load('UserId') === 'undefined') ? '' : cookie.load('UserId');
		var proqtyQty = $("#proIndex-"+IndexFlg).find('.proqty_input').val();
		
		var postObject = {};
		postObject = {
			'app_id': appId,
			"product_id": productId,
			"product_qty": proqtyQty,
			"product_type": 1,
			"availability_id": availabilityId,
			"availability_name": availabilityName,
			"isAddonProduct": isAddonProduct,
			"reference_id":  (customerId === '') ? getReferenceID() : '',
			"customer_id": customerId
		};

		Axios.post(apiUrlV2 + "cart/simpleCartInsert", qs.stringify(postObject)).then(res => {
			$("#proIndex-"+IndexFlg).removeClass('active');
			hideLoader('proIndex-'+IndexFlg,'Idtext');
			$("#proIndex-"+IndexFlg).find('.addcart_done_maindiv').hide();
		    $("#proIndex-"+IndexFlg).find('.smiple_product_lk').show();
			if (res.data.status === "ok") {
				this.props.sateValChange('cartflg', 'yes');
				removePromoCkValue();
				showCustomAlert('success','Great choice! Item added to your cart.');
				/*showCartLst();*/
				this.handleShowAlertFun('success','Great choice! Item added to your cart.');
			} else if (res.data.status === "error") {
				var errMsgtxt = (res.data.message !== '') ? res.data.message : "Sorry! Products can`t add your cart.";
				showCustomAlert('error',errMsgtxt);
				this.handleShowAlertFun('Error',errMsgtxt);
			}
			
            return false;
			
		});
		
	}
	
  }
  
  favourActFun(productDetail, event) {
	   event.preventDefault();
	   var productPrimaryId = productDetail.product_primary_id;
	   
	   var actionFlgText = 'Yes';
	   if(productDetail.fav_product_primary_id === productDetail.product_primary_id) {
		  actionFlgText = 'No';
	  }
	   
	   var postObject = {};
		postObject = {
			'app_id': appId,
			"outlet_id": cookie.load('orderOutletId'),
			"product_id": productPrimaryId,
			"fav_flag": actionFlgText,
			"availability_id": cookie.load("defaultAvilablityId"),
			"customer_id": cookie.load('UserId')
		};
		
		var succesFlgTxt = (actionFlgText === 'Yes') ? 'Great choice! Item added to your favourite list.' : 'Item was removed to your favourite list.';
		var errorFlgTxt = (actionFlgText === 'Yes') ? 'Sorry! Products can`t add your favourite list.' : 'Sorry! Products can`t remove your favourite list.';
	   
	    Axios.post(apiUrl + "products/favourite_products_add", qs.stringify(postObject)).then(res => {
			if (res.data.status === "ok") {
				this.handleShowAlertFun('success',succesFlgTxt);
			} else if (res.data.status === "error") {
				var errMsgtxt = (res.data.message !== '') ? res.data.message : errorFlgTxt;
				this.handleShowAlertFun('Error',errMsgtxt);
			}
			this.props.sateValChange('favTgr', 'Yes');
            return false;
		});
  }
  
  viewProDetail(productDetail, event) {
	  event.preventDefault();
	  var productSlug = productDetail.product_slug;
	  if(productSlug !== '') {
		$("#proIndex-"+productDetail.product_primary_id).addClass('active');
		this.props.sateValChange('view_pro_data', productSlug);
	  }
	  return false;
  }
  
  handleShowAlertFun(header,msg) {
		var magnfPopup = $.magnificPopup.instance;
		showAlert(header, msg, magnfPopup);
		$.magnificPopup.open({
		  items: {
			src: '.alert_popup'
		  },
		  type: 'inline'
		});
  }

  getCateProductList(navigateMenu) {
    if (navigateMenu !== "" && navigateMenu.length > 0) {
      var updateproductList = [];
      var current = this;
      navigateMenu.map(function (item) {
        var availabilityId =
          cookie.load("defaultAvilablityId") === undefined ||
          cookie.load("defaultAvilablityId") == ""
            ? deliveryId
            : cookie.load("defaultAvilablityId");

				var orderOutletId = (cookie.load("orderOutletId") === undefined || cookie.load("orderOutletId") == '' )?'':cookie.load("orderOutletId");

            
        Axios.get(
          apiUrlV2 +
            "products/getAllProducts?app_id=" +
            appId +
            "&availability=" +
            availabilityId +
            "&category_slug=" +
            item.pro_cate_slug +
            "&outletId=" +
            orderOutletId
        )
          .then(function (response) {
            if (response.data.status === "ok") {
              if (current.state.imagesource === "") {
                current.setState({ imagesource: response.data.common });
              }
              var result = response.data.result_set;
              updateproductList[item.pro_cate_slug] = result;
              //if (navigateMenu.length - 1 === index) {
              current.productsubcatlist(updateproductList, navigateMenu);
              // }
            } else {
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    }
  }
  
  
  getFavActivCls(product) {
	  var favActCls = "disbl_href_action favour-icon-link";
	  if(product.fav_product_primary_id === product.product_primary_id) {
		  favActCls = favActCls+' active';
	  }
	  
	  return favActCls;
  }
  
  /* Addon product Listing */
  favouriteProductListing() {
	    var favouriteProductlst = this.props.favouriteproductlist;
		var clientProductStock = '';
		if(Object.keys(this.props.globalsettings).length > 0) {
			  if(this.props.globalsettings[0].status === 'ok') {
				  var globalSettingsArr = this.props.globalsettings[0].result_set;
				  if(Object.keys(globalSettingsArr).length > 0) {
					  clientProductStock = globalSettingsArr.client_product_stock;
				  }			  
				}
		}
		if (Object.keys(favouriteProductlst).length > 0) {
			var favouriteProlstOnly = favouriteProductlst;
			var favouriteCommonImg = this.props.favouriteproductcommon.product_image_source;
			const Phtml = favouriteProlstOnly.map((product, index) =>

					<div className={this.chkProStockCls(product.product_slug,product.product_stock,clientProductStock)} id={"proIndex-"+product.product_primary_id} key={index}>
					
					    <div className="products-image-div">
						    {cookie.load("UserId") &&  <a className={this.getFavActivCls(product)} href="/" onClick={this.favourActFun.bind(this,product)}>
							<img className="disbl_href_action favour-heartwhite-icon" src={heartWhite} />
							<img className="disbl_href_action favour-heartyellow-icon" src={heartYellow} />
						</a>}
							{(product.product_thumbnail !== '')?<img src={favouriteCommonImg+'/'+product.product_thumbnail} />:<img src={noimage} />}
						</div>
						
						<div className="product-info-div">
						  <div className="product-title-maindiv">
							   <div className="product-title">
								 <h3>{product.product_alias !== '' ? stripslashes(product.product_alias) : stripslashes(product.product_name)}</h3>
							   </div>
						   </div>

						   <div className="product-short-description"><p>{(product.product_short_description !== null && product.product_short_description !== '') ? Parser(stripslashes(product.product_short_description)) : Parser('&nbsp;')}
						   </p></div>
						   
						   <div className="product-price">
								<h3>{showPriceValue(product.product_price)}</h3>
						   </div>
						   
						   <div className="products-ordernow-action">
						   {(product.product_stock > 0 || product.product_stock === null || clientProductStock !== '1')?((product.product_type === '1')?<a className="button order_nowdiv smiple_product_lk disbl_href_action" href="/" onClick={this.viewProDetail.bind(this,productDetail)}>Add to Cart</a>:<a href="/" onClick={this.viewProDetail.bind(this,product)} title="Product Details" id={"comboPro-"+product.product_slug} className="button order_nowdiv compo_product_lk">Order Now</a>):<a className="button order_nowdiv disabled disbl_href_action" href='/'>Sold Out</a>}
							
							<div className="addcart_row addcart_done_maindiv">
								<div className="qty_bx">
									<span className="qty_minus" onClick={this.proQtyAction.bind(this,product.product_primary_id, 'decr')}>-</span>
									<input type="text" className="proqty_input" readOnly value="1" />
									<span className="qty_plus" onClick={this.proQtyAction.bind(this,product.product_primary_id, 'incr')}>+</span>
								</div>
								<button className="btn btn_black" onClick={this.addToCartSimple.bind(this,product,'done',Array())}>Done</button>
							</div>
						   </div>
						   
						</div>

						
				</div>);

			return Phtml;
					
		} else {
			return false;
		}


  }
	
  componentDidMount() {
  setTimeout(function () {
    $("html, body").animate(
      {
        scrollTop: $(".product-category-list").offset().top - 1000,
      },
      1000
    );
	},1000);
  }

  render() {
	  
	var settingsFavourite = {
			infinite: false,
			dots:false,
			slidesToShow: 5,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 1191,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: false
				}
			},
			{
				breakpoint: 850,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2

				}
			},
			{
				breakpoint: 680,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
			]

		};  
	  
	  
    return (<div className="productlist-favour-main-div" >
		     
			 
			 {/* favourite products main div - start */}
			 {(Object.keys(this.props.favouriteproductlist).length > 0) && <div className="favour-slider-topdiv">
			     {cookie.load("UserId") &&  <div className="addonpro-slider-top new-favour">
			     <div className="chk-addonpro-title">
					<h3>Your Favourites</h3>
				 </div>
				 <div className="detail-pg-slider">
					<Slider {...settingsFavourite}>
						{this.favouriteProductListing()}
					</Slider>
				 </div>
				 </div>}
			 </div>}
			 {/* favourite products main div - end */} 
			 
			 <div className="productlist-main-div">
			 
			 <div className="innerproduct_row">
          {this.state.product_List}
          {this.state.productDetails}
			 </div>
			 
			 </div>
			
		  </div>)
  }

}

const mapStateTopProps = (state) => {
  var tempArr = Array();var productSubcat = Array();var productCmn = Array();
  if(Object.keys(state.product).length > 0) {
	  var tempArr = (!('productlist' in state.product[0])) ? Array() : state.product[0].productlist;
	  if(Object.keys(tempArr).length > 0) {
		 if(tempArr[0].status === 'ok') {
			 productSubcat = tempArr[0].result_set[0].subcategorie;
			 productCmn = tempArr[0].common;
		 }
	  }
  }

  var favouriteProArr = Array();
  var favouriteCommonArr = Array();
  if(Object.keys(state.favouriteproduct).length > 0) {
	  if(state.favouriteproduct[0].status === 'ok' && Object.keys(state.favouriteproduct[0].result_set).length > 0) {
		favouriteProArr = state.favouriteproduct[0].result_set;
		favouriteCommonArr = state.favouriteproduct[0].common;
	  }
  }  
  
  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn,
	favouriteproductlist: favouriteProArr,
    favouriteproductcommon: favouriteCommonArr
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (catslug, subcatslug, outletid) => {
      dispatch({ type: GET_PRODUCT_LIST, catslug, subcatslug, outletid });
    },
	getFavouriteProList: (outletId) => {
      dispatch({ type: GET_FAVOURITEPRODUCT, outletId });
    },
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);