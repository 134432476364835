import { SET_CATERING_PRODCUTLIST } from '../actions';

const cateringprodcutlist = (state = [], action) => {
  switch (action.type) {
    case SET_CATERING_PRODCUTLIST:
      return [...action.value];
    default: return state;
  }
}

export default cateringprodcutlist;