export const appId        = "389C7769-7311-48D1-8FC3-C8B7BE1D3BC6";
/* Live 

export const apiUrl       = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2     = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlPro    = "https://ccpl.ninjaos.com/ninjapro/";
export const apiUrlCtrng  = "https://ccpl.ninjaos.com/cateringv2/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";
export const baseUrl      = "https://www.theshepherdspie.com/";
export const timThumpUrl  = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const blogImageUrl = "https://ccpl.ninjaos.com/media/dev_team/blog/";
export const proFolderUrl = "https://ccpl.ninjaos.com/media/dev_team/products/";
*/

/* Dev */
export const apiUrl       = "https://ccpl.promobuddy.asia/api/";
export const apiUrlV2     = "https://ccpl.promobuddy.asia/apiv2/";
export const apiUrlPro    = "https://ccpl.promobuddy.asia/ninjapro/";
export const apiUrlCtrng  = "https://ccpl.promobuddy.asia/cateringv2/";
export const apiUrlNotify = "https://ccpl.promobuddy.asia/Pushorder/";
export const baseUrl      = "https://www.demosite.theshepherdspie.com/";
export const timThumpUrl  = "https://ccpl.promobuddy.asia/timthumb.php?src=";
export const blogImageUrl = "https://ccpl.promobuddy.asia/media/dev_team/blog/";
export const proFolderUrl = "https://ccpl.promobuddy.asia/media/dev_team/products/";

export const stripeImage  = "/img/stripe_logo.png";

export const deliveryId  = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId    = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId    = "EB62AF63-0410-47CC-9464-038E796E28C4";
export const reservationId    = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";

export const CountryTxt   = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany    = "Shepherd Pie";

export const fbAppId    = "2217773351628980";

export const stripeKey    = "pk_test_hLfht4KEShHlACDG9mBfrPkv";
export const stripeEmail    = "dev@jankosoft.com";
export const stripeReference    = "shepherd";
export const stripeDesc    = "My Checkout";
export const stripeCurrency    = "SGD";
 
