import { SET_FAQ_LIST } from '../actions';

const faqlist = (state = [], action) => {
  switch (action.type) {
    case SET_FAQ_LIST:
      return [...action.value];
    default: return state;
  }
}

export default faqlist;